import { Component, createRef, h, JSX } from 'preact'
import SelectElement from 'components/select-preact/select-element'
import selectHelper from 'services/select-helper'
import { i18n } from 'i18n/i18n'

export interface SelectOption {
  value: string
  template: JSX.Element | string
  searchValue?: string
}

export interface SelectPreactProps {
  options: SelectOption[]
  chosenItem: SelectOption
  onChange: (el: SelectOption) => void
  name: string
  id: string
  listClasses?: string
  dropdownClasses?: string
  buttonClasses?: string
  isSearch?: boolean
  isOptionClickBlocked?: (option: string) => boolean
}

export interface SelectPreactState {
  isOpen: boolean
  searchValue: string
}

export default class SelectPreact extends Component<SelectPreactProps, SelectPreactState> {
  private dropdownList = createRef<HTMLDivElement>()
  private searchInput = createRef<HTMLInputElement>()

  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      searchValue: '',
    }
  }

  public componentDidMount() {
    window.addEventListener('click', this.closeSelectOnOutsideClick)
  }

  public componentWillUnmount() {
    window.removeEventListener('click', this.closeSelectOnOutsideClick)
  }

  public render() {
    return (
      <div className={`bem-select-dropdown ${this.props.dropdownClasses || ''}`} ref={this.dropdownList}>
        <button
          className={`bem-select-dropdown__button w-dropdown-toggle ${this.props.buttonClasses || ''}`}
          aria-controls="dropdown"
          aria-haspopup="menu"
          aria-expanded="false"
          type="button"
          onClick={this.toggleSelect}
        >
          <span className="bem-select-dropdown__chosen-item">{this.props.chosenItem.template}</span>
          <span className="bem-select-dropdown__button-handle is-more-flat" />
        </button>
        <nav
          className={`bem-select-dropdown__list ${this.props.listClasses || ''} ${this.state.isOpen ? 'is-open' : ''}`}
          aria-labelledby="dropdown"
        >
          {this.props.isSearch && (
            <div className="bem-select-dropdown__search">
              <input
                ref={this.searchInput}
                type="text"
                className="bem-select-dropdown__search-input"
                placeholder={i18n('preactSelect.search')}
                onInput={this.onSearch}
              />
            </div>
          )}
          <div className="bem-select-dropdown__results">
            {this.filteredOptions.map((option) => (
              <SelectElement
                onClick={this.selectElement}
                option={option}
                key={option.value}
                isOptionClickBlocked={this.props.isOptionClickBlocked}
              />
            ))}
          </div>
        </nav>
        <select name={this.props.name} id={this.props.id} hidden value={this.props.chosenItem.value}>
          {this.filteredOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.value}
            </option>
          ))}
        </select>
      </div>
    )
  }

  public onSearch = (event): void => {
    this.setState({ searchValue: event.target.value || '' })
  }

  public closeSelectOnOutsideClick = (event: MouseEvent): void => {
    if (!this.dropdownList.current.contains(event.target as Node) && this.state.isOpen) {
      this.setState({ isOpen: false })
    }
  }

  public selectElement = (option: SelectOption) => {
    this.props.onChange(option)
    this.toggleSelect()
  }

  public toggleSelect = async () => {
    await this.setState({ isOpen: !this.state.isOpen })
    if (this.state.isOpen && this.props.isSearch) {
      this.searchInput.current.focus()
    }
    this.resetSearchInputAndState()
  }

  public resetSearchInputAndState = (): void => {
    this.setState({ searchValue: '' })
    if (this.searchInput.current) {
      this.searchInput.current.value = ''
    }
  }

  get filteredOptions(): SelectOption[] {
    return this.props.isSearch
      ? selectHelper.getFilteredSelectOptions(this.props.options, this.state.searchValue)
      : this.props.options
  }
}
