import { Component, h } from 'preact'
import { SelectOption } from 'components/select-preact/select-preact'

export interface SelectElementProps {
  option: SelectOption
  onClick: (option: SelectOption) => void
  isOptionClickBlocked?: (option: string) => boolean
}

export default class SelectElement extends Component<SelectElementProps, {}> {
  public render() {
    return (
      <button
        className="bem-select-dropdown__select-button"
        tabIndex={0}
        type="button"
        onClick={this.selectElementIfNotBlocked}
        aria-label={this.props.option.value}
      >
        {this.props.option.template}
      </button>
    )
  }

  public selectElement = (): void => {
    this.props.onClick(this.props.option)
  }

  public selectElementIfNotBlocked = (): void => {
    this.props.isOptionClickBlocked && this.props.isOptionClickBlocked(this.props.option.value)
      ? undefined
      : this.selectElement()
  }
}
