import { SelectOption } from 'components/select-preact/select-preact'
import { Component, h, JSX } from 'preact'
import currencyHelper from 'services/currency-helper'
import selectHelper from 'services/select-helper'
import { i18n } from 'i18n/i18n'
export interface CalculatorSelectProps {
  onChange: (option: SelectOption) => void
  currencies: string[]
  value: string
}

export interface CalculatorSelectState {
  options: SelectOption[]
  chosenItem: SelectOption
}

export default class CalculatorSelect extends Component<CalculatorSelectProps, CalculatorSelectState> {
  constructor(props) {
    super(props)

    const options = this.createOptions()
    this.state = {
      options,
      chosenItem: options[0],
    }
  }

  public componentDidUpdate(previousProps: Readonly<CalculatorSelectProps>) {
    if (previousProps.currencies.length !== this.props.currencies.length) {
      const options = this.createOptions()
      this.setState({ options, chosenItem: selectHelper.findByValue(this.props.value, options) })
    }
  }

  public render() {
    return null
  }

  public getOptionLabel = (currency: string): JSX.Element => (
    <span className="hero-form__currency-item clearfix">
      <span
        className={`hero-form__currency-flag icon-currency-round-${currency} icon-currency-round-${currency}-dims`}
      />
      <span className="hero-form__currency-label">{currency}</span>
      <span className="hero-form__currency-sublabel">{`${i18n(`currencies.${currency}`)}`}</span>
    </span>
  )

  public createOptions = (): SelectOption[] => {
    const options = this.props.currencies.map((cur) =>
      selectHelper.createOption(
        cur,
        this.getOptionLabel(cur),
        `${cur} ${i18n(`currencies.${cur}`)} ${currencyHelper.getCurrencyCountry(cur)}`,
      ),
    )
    return options.length ? options : [selectHelper.getEmptyOption()]
  }

  public onChange = (option: SelectOption): void => {
    this.setState({ chosenItem: option })
    this.props.onChange(option)
  }
}
