import classNames from 'classnames'
import { Fragment, h } from 'preact'
import MainCalculatorErrorView from 'app-main/calculator/calculator-error'
import MainCalculatorRouteFusionView from 'app-main/calculator/calculator-rf-info'
import MainCalculatorInput from 'app-main/calculator/calculator-input'
import MainCalculatorRateView from 'app-main/calculator/calculator-rate'
import CalculatorFormView from 'common-modules/calculator/calculator-form'
import marketService from 'services/market'
import MainCalculatorContinueButton from 'app-main/calculator/calculator-continue-button'
import { i18n, initI18n } from 'i18n/i18n'

declare global {
  interface Window {
    DIRECT_LOGIN_BASE_URL_IK: string
  }
}

export default class MainCalculatorFormView extends CalculatorFormView {
  public async componentDidMount() {
    await initI18n()

    this.setState(
      {
        ...(this.formFromLS ? { form: this.formFromLS } : {}),
        marketBrief: marketService.getMarketBrief(),
      },
      this.saveFormToLS,
    )

    window.addEventListener('marketBrief fetched', this.onMarketBriefFetch)
  }

  public componentWillUnmount() {
    window.removeEventListener('marketBrief fetched', this.onMarketBriefFetch)
  }

  public render() {
    return (
      <Fragment>
        <div className="hero-form__section for-first-step">
          {this.isSelectedCurrencyNotSupportedSales && (
            <MainCalculatorRouteFusionView baseCurrency={this.state.form.baseCurrency.val} />
          )}
          {this.state.isError && <MainCalculatorErrorView />}
          <div className="hero-form__section-inner for-step-1">
            <div className="hero-form__fields-with-connector">
              <MainCalculatorInput
                name="baseAmount"
                label={i18n('calculator.buyLabelName')}
                amount={this.state.form.baseAmount.val}
                currency={this.state.form.baseCurrency.val}
                currencies={this.props.currencies}
                handleAmountChange={this.handleAmountChange}
                handleCurrencyChange={this.handleCurrencyChange}
              />
              <div className="hero-form__fields-connector">
                <div className="hero-form__connector-line" />
                <div className="hero-form__swap-holder">
                  <button
                    type="button"
                    className={classNames('hero-form__swap-icon bem-icon is-swap-icon', {
                      'is-swapped': this.state.isSwapped,
                    })}
                    onClick={this.swapInputs}
                  />
                </div>
              </div>
              <MainCalculatorInput
                name="counterAmount"
                label={i18n('calculator.sellLabelName')}
                className="is-second"
                amount={this.state.form.counterAmount.val}
                currency={this.state.form.counterCurrency.val}
                currencies={this.props.currencies}
                handleAmountChange={this.handleAmountChange}
                handleCurrencyChange={this.handleCurrencyChange}
              />
            </div>
          </div>
          <MainCalculatorRateView
            baseCurrency={this.state.form.baseCurrency.val}
            counterCurrency={this.state.form.counterCurrency.val}
            direction={this.direction}
            marketBrief={this.state.marketBrief}
            shouldUpdate={this.isFormWithDiffCurrencies && this.isFormWithPLN}
          />
        </div>
        <MainCalculatorContinueButton hrefLink={this.hrefLink} />
      </Fragment>
    )
  }

  get hrefLink(): string {
    // This is most likely caused by using DOM or Web APIs.
    // Pre-render runs in node and has no access to globals available in browsers.
    const baseUrl = typeof window !== 'undefined' ? window.DIRECT_LOGIN_BASE_URL_IK : ''

    const calculatorData = {
      soldCurrency: this.state.form.counterCurrency.val,
      soldAmount: this.state.form.counterAmount.val,
      boughtCurrency: this.state.form.baseCurrency.val,
      boughtAmount: this.state.form.baseAmount.val,
      rate: this.state.compareResult?.exchangeRate,
      rateTs: this.state.compareResult?.rateTs,
      eurEquivalent: this.state.compareResult?.eurEquivalent,
      calculateDirection: this.state.form.exchangeDirection.val === 'forward' ? 'forward' : 'back',
    }
    return `${baseUrl}?calculatorData=${encodeURIComponent(JSON.stringify(calculatorData))}#/wbr`
  }
}
